import React from 'react';

const RedirectDesktop = () => {
    return (
        <div>
            <p>Plus qu'à télécharger l'application sur iOS ou Android, te connecter avec ce compte, et commencer ton entraînement !</p>
        </div>
    );
};

export default RedirectDesktop;