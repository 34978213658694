import React, {useEffect, useState} from 'react';
import RedirectDesktop from "./content/RedirectDesktop";
import RedirectMobile from "./content/RedirectMobile";
import logoFlit from "../../../assets/10.png";

const RedirectContent = () => {

    const [mobile,setMobile] = useState(false)

    useEffect(() => {

        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
        if (toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem)
        })) {
            return setMobile(true)
        } else {
            setMobile(false)
        }
    }, [])

    return (
        <div className={"events_redirect_global"}>

            <div>
                <img className={"events_logo"} src={logoFlit} alt={"logoflit"}/>
                <h1 className={""}>Inscription Réussie !</h1>
                <p>La promotion a été prise en compte. Tu pourras obtenir ton plan d’entraînement en te connectant avec ce compte à l’application Flit Run </p>
            </div>

            {
                mobile ? <RedirectMobile/> : <RedirectDesktop/>
            }

        </div>
    );
};

export default RedirectContent;