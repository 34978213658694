import React from 'react';
import logoApple from "../../../../assets/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg"
import logoGoogle from "../../../../assets/google-play-badge.png"

const RedirectMobile = () => {

    return (
        <div className={"events_redirect_box"}>

            <img onClick={() => window.location.href = " https://apps.apple.com/fr/app/flit-run/id1643142031"}
                 className={"events_logo_redirect"} src={logoApple} alt={"apple logo, redirect to mobile app"}/>
            <img
                onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=run.flit.FlitRun"}
                className={"events_logo_redirect_android"} src={logoGoogle} alt={"android logo, redirect to mobile app"}/>
        </div>
    );
};

export default RedirectMobile;