import React, {useEffect, useState} from 'react';
import axios from "axios"
import {useLocation, useNavigate, useParams} from "react-router";
import './events.css'
import {IoEyeOffSharp, IoEyeSharp} from "react-icons/io5";
import logoFlit from "../../assets/2.png"
import {useSearchParams} from "react-router-dom";

const Events = () => {

    const [data, setData] = useState({
        lastName: null,
        email: null,
        password: null
    })


    const useQuery = () => {
        const {search} = useLocation()

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    // const query = useQuery();
    // console.log(query.get())
    // console.log(query.get("parrain"))
    const {search} = useLocation()
    console.log(search)

    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);


    const handleChangeDataevents = (e) => {
        setData({...data, [e.target.name]: e.target.value});
    };

    const handlePassword = (e) => {
        if (e.target.name === "password") {
            if (e.target.value.length >= 1 && e.target.value.length < 5) {
                setPasswordValid(true)
            } else {

                setData({...data, password: e.target.value});
                setPasswordValid(false)
            }
        }
    }


    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const handleSubmit = () => {
        if (data.email === null || data.password === null | data.lastName === null) {
            alert("Il faut que tu remplisse tous les champs")
        } else {
            axios
                .post(`${process.env.REACT_APP_STAGING_V2}/user/create`, {
                    debug: false,
                    data,
                    args: {
                        utm: search
                    }
                })
                .then((res) => {
                    if (res.status === 200) {

                        navigate('/events/redirect')


                    }
                })
                .catch((err) => console.error(err));
        }
    }


    return (
        <div className={"events"}>
            <div>
                <img className={"events_logo"} src={logoFlit} alt={"logoflit"}/>
                <h2>Bienvenue chez Flit Run</h2>
                <p>Crée ton compte en quelques secondes pour bénéficier de l’offre promotionnelle.</p>
            </div>
            <ul className={"events_list"}>
                <li className={"events_list_item"}>
                    <input onChange={(e) => handleChangeDataevents(e)} name={'lastName'}
                           placeholder={"Prénom"} className={"events_list_input"}/>
                </li>
                <li className={"events_list_item"}>
                    <input type={"email"} onChange={(e) => handleChangeDataevents(e)} name={"email"}
                           placeholder={"Adresse e-mail"} className={"events_list_input"}/>
                </li>
                <li className={"events_list_item"}>
                    <label className={"events_list_input_label"}>
                        <input name={"password"} className={"events_list_input_password"}
                               onChange={(e) => handlePassword(e)} type={showPassword ? "text" : "password"}
                               placeholder={"Mot de passe"}/>
                        {
                            showPassword ? <IoEyeSharp className={"events_eyes"} onClick={handleShowPassword}/> :
                                <IoEyeOffSharp className={"events_eyes"} onClick={handleShowPassword}/>
                        }

                    </label>

                </li>
                <button className={"events_button"} onClick={handleSubmit}>S'inscrire</button>
            </ul>

            <div></div>

        </div>
    );
};

export default Events;